export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/India-logo.png',
    banner_img_root : '/img/India-banner.png',
    resid : 'c43b46999f5e9ef872f608777020bbc2',
    key_value : 'indiaoven',
    secret_value : 'indiaoven',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12285.349111831161!2d-104.93882000000002!3d39.664627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5d16e68428336d6!2sIndia%20Oven!5e0!3m2!1sen!2sin!4v1598856047863!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"India Oven"
};
